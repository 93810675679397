<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Статистика просмотров</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <cond-stat class="mb-4"></cond-stat>
      <diagram
        style="position: relative; height: 400px"
        v-if="chart"
        :chart-data="chart"
        class="mb-4"
      ></diagram>
      <v-row class="align-center">
        <v-col v-if="books.length > 0" cols="12">
          <v-select
            v-model="selectBook"
            :items="books"
            item-text="title"
            item-value="id"
            label="Книга"
            prepend-icon="$vuetify.icons.bookshelf"
            @change="sortStatistics"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="align-center mb-4">
        <div class="d-flex px-4 pb-4">
          <v-btn
            v-for="(item, index) in periods"
            :key="index"
            small
            class="mr-2"
            @click="changePeriod(item.val)"
            :color="period == item.val ? 'primary' : ''"
          >
            {{ item.text }}</v-btn
          >
        </div>
      </v-row>
      <v-row v-if="!statistics || statistics.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>
      <v-data-table
        v-else
        :headers="headersTable"
        :items="statistics"
        :items-per-page="-1"
        class=""
        item-key="id_table"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CondStat from "./CondStat.vue";
import Diagram from "./Diagram.vue";

export default {
  components: {
    CondStat,
    Diagram,
  },
  data: () => ({
    headersTable: [
      {
        text: "Дата",
        align: "start",
        sortable: false,
        value: "date",
      },
      // { text: "Книга", value: "book_title" },
      { text: "Количество прочтений", value: "count" },
      { text: "Завершенных", value: "end" },
    ],
    selectBook: "all",
    // datacollection: null,
    period: "currentMonth",
    periods: [
      { text: "Последние 30 дней", val: "currentMonth" },
      { text: "Предыдущий месяц", val: "lastMonth" },
      { text: "За год", val: "year" },
      { text: "За все время", val: "all" },
    ],
  }),
  methods: {
    getStatistics() {
      //event.preventDefault();
      this.loading = true;
      let app = this;
      this.$store.dispatch("cabinet_statistics/getStatisticsViewsPeriod", {
        book_id: app.selectBook,
        period: app.period,
      });
    },

    sortStatistics() {
      if (
        this.period != this.$route.query.period ||
        this.selectBook != this.$route.query.book_id
      ) {
        this.$router.push({
          name: this.$route.name,
          query: {
            book_id: this.selectBook,
            period: this.period,
          },
        });
      }
    },
    changePeriod(value) {
      this.period = value;
      this.sortStatistics();
    },
    // fillData() {
    //   this.datacollection = {
    //     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    //     datasets: [
    //       {
    //         label: 'Статистика',
    //         data: [4, 5, 3, 5, 2, 3],
    //         backgroundColor: "rgba(255, 99, 132, 0.2)",
    //         borderColor: "rgba(255, 99, 132, 1)",
    //         borderWidth: 2,
    //       },
    //     ],
    //   };
    // },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      statistics: "cabinet_statistics/statistics",
      books: "cabinet_statistics/books",
      chart: "cabinet_statistics/chart",
    }),
  },
  mounted() {
    this.selectBook = this.$route.query.book_id
      ? this.$route.query.book_id
      : "all";
    if (this.selectBook != "all") {
      this.selectBook = +this.selectBook;
    }
    this.period = this.$route.query.period
      ? this.$route.query.period
      : "currentMonth";

    this.getStatistics();
    // this.fillData();
  },
};
</script>
